import React from 'react';

const Solutions = () => {
  return (
    <div>
      TEST PAGE
    </div>
  )
};

export default Solutions;
